import React from 'react'
import { useAuth0 } from '../../react-auth0-spa'
import RegistrationButton from '../Buttons/RegistrationButton'
import RibbonContainer from './RibbonContainer'
import LeftContainer from './LeftContainer'
import RightContainer from './RightContainer'

const RegistrationRibbon = () => {
  const { loginWithRedirect } = useAuth0()

  const onClickRegister = () => {
    loginWithRedirect({ mode: 'signUp' })
  }

  return (
    <RibbonContainer>
      <LeftContainer>
        <h3>
          Atrévete a probar un sistema diferente para aprender inglés,
          regístrate aquí y <span>obtén 3 módulos de inglés gratis.</span>
        </h3>
      </LeftContainer>
      <RightContainer>
        <RegistrationButton
          onClickRegister={() => onClickRegister()}
        ></RegistrationButton>
      </RightContainer>
    </RibbonContainer>
  )
}

export default RegistrationRibbon
