import styled from '@emotion/styled'

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
`

export default RightContainer
