import styled from '@emotion/styled'

const LeftContainer = styled.div`
  width: 65%;
  padding-left: 27rem;
  h3 {
    font-size: 3rem;
    font-weight: 400;
    color: white;
    span {
      color: #00d0bf;
    }
  }

  @media screen and (max-width: 1350px) {
    padding-left: 5%;
  }

  @media screen and (max-width: 992px) {
    h3 {
      font-size: 2.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    h3 {
      font-size: 2rem;
    }
  }

  @media screen and (max-width: 575px) {
    width: 80%;
    margin-bottom: 2rem;
  }
`

export default LeftContainer
