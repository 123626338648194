import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const RegistrationButton = ({ RegistrationButtonTitle, onClickRegister }) => {
  const RegisterButton = styled.button`
    text-decoration: none;
    font-size: 2.5rem;
    font-weight: bold;
    font-size: 2.7rem;
    font-weight: bold;
    padding: 0.7rem 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 992px) {
      font-size: 2.2rem;
    }

    @media screen and (max-width: 768px) {
      font-size: 1.8rem;
    }
  `

  return (
    <RegisterButton className="gradient-button-2" onClick={onClickRegister}>
      {RegistrationButtonTitle}
    </RegisterButton>
  )
}

RegistrationButton.defaultProps = {
  RegistrationButtonTitle: 'Registrarme',
}

RegistrationButton.propTypes = {
  RegistrationButtonTitle: PropTypes.string,
}

export default RegistrationButton
