import React from 'react'
import { Link } from 'gatsby'
import { useAuth0 } from '../react-auth0-spa'
import Layout from '../components/Layout'
import SocialSideBar from '../components/SocialSideBar'
import RegistrationRibbon from '../components/RegistrationRibbon'
import Subscriptions from '../components/Subscriptions/Subscriptions'
import styles from './index.module.scss'
import DesktopIcon from '../images/png/start_desktop.png'
import MobileIcon from '../images/png/start_mobile.png'
import LaptopIcon from '../images/png/start_laptop.png'
import FlowIcon from '../images/png/start_flow.png'
import CommerceIcon from '../images/png/start_commerce.png'
import EyeIcon from '../images/png/start_eye.png'
import GlobeIcon from '../images/png/start_globe.png'
import WorldOneIcon from '../images/png/start_world_1.png'

const Index = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const youtubeVideo_1 = 'https://www.youtube.com/embed/55XONN_lfBM'
  const youtubeVideo_2 = 'https://www.youtube.com/embed/auBCiKrmzbQ'

  const onClickRegister = () => {
    loginWithRedirect({ mode: 'signUp' })
  }

  return (
    <div>
      <Layout>
        <main>
          <section className={styles.sectionOne}>
            <SocialSideBar />
            <div className={styles.imageContainer}>
              <div className={styles.textContainer}>
                <h2>
                  Conéctate con FluentWorlds y habla inglés de forma intuitiva,
                  rápida y fluída.
                </h2>
                {!isAuthenticated && (
                  <>
                    <h4>
                      Regístrate aquí y obtén{' '}
                      <span>3 módulos de inglés gratis.</span>
                    </h4>
                    <div className={styles.buttonContainer}>
                      <button
                        className="gradient-button-2"
                        onClick={onClickRegister}
                      >
                        Registrarme
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>

          <div className={styles.sectionTwo}>
            <div className={styles.desktop}>
              {/* <DesktopIcon className={styles.sectionTwoIcon} /> */}
              <img
                src={DesktopIcon}
                alt="desktop"
                className={styles.sectionTwoIcon}
              />
              <h2>Desktop</h2>
              <h4>
                Accede a nuestro sistema completo{' '}
                <span>desde la comodidad de tu casa.</span>
              </h4>
            </div>
            <div className={styles.mobile}>
              {/* <MobileIcon className={styles.sectionTwoIcon} /> */}
              <img
                src={MobileIcon}
                alt="mobile"
                className={styles.sectionTwoIcon}
              />
              <h2>Móvil</h2>
              <h4>
                Accede a nuestras 3 apps <span>desde cualquier lugar.</span>
              </h4>
            </div>
            <div className={styles.academy}>
              {/* <LaptopIcon className={styles.sectionTwoIcon} /> */}
              <img
                src={LaptopIcon}
                alt="academy"
                className={styles.sectionTwoIcon}
              />
              <h2>Academy</h2>
              <h4>
                Sistema de educación inmersivo en línea{' '}
                <span>con profesores nativos.</span>
              </h4>
            </div>
            <div className={styles.products}>
              <h1>Nuestros productos</h1>
              <h3>
                Un sistema diferente en 3D{' '}
                <span>para aprender a hablar en inglés.</span>
              </h3>
            </div>
          </div>

          <div className={styles.sectionThree}>
            <div className={styles.imageContainer}>
              <div className={styles.textContainer}>
                <h1>Conéctate</h1>
                <h1>con el mundo,</h1>
                <h1>hablando inglés.</h1>
              </div>
              {!isAuthenticated && (
                <div className={styles.registerContainer}>
                  <div className={styles.top}>
                    <h4>
                      Regístrate aquí y obtén{' '}
                      <span>3 módulos de inglés gratis.</span>
                    </h4>
                    <div className={styles.inputContainer}>
                      <input type="text" placeholder="Nombre *" />
                      <input type="text" placeholder="Email *" />
                      <input type="text" placeholder="Celular *" />
                      <input type="text" placeholder="Ciudad *" />
                      <div className={styles.checkboxContainer}>
                        <input
                          type="checkbox"
                          id="accept"
                          name="accept"
                          value="accept"
                        />
                        <label htmlFor="accept">
                          {' '}
                          Acepto las politicas de privacidad y confidencialidad
                          para uso de mis datos.
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={styles.bottom}>
                    <button
                      className="gradient-button-2"
                      onClick={onClickRegister}
                    >
                      Registrarme
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles.sectionFour}>
            <div className={styles.left}>
              {/* <FlowIcon className={styles.sectionFourFlowIcon} /> */}
              <img
                src={FlowIcon}
                alt="flow"
                className={styles.sectionFourFlowIcon}
              />
              <h2>Nuestro sistema integrado de aprendizaje</h2>
            </div>
            <div className={styles.right}>
              <h1>¿Por qué </h1>
              <h1>FluentWorlds?</h1>
              <h3>
                Un sistema diferente, <span>para aprender inglés en 3D.</span>
              </h3>
              <p>
                Nuestras innovadoras tecnologias de enseñanza inmersiva de
                idiomas en 3D, potencializan el poder de presencia e interacción
                en niveles simulados de un “mundo real”, creando un nuevo modelo
                transformativo para dispositivos móviles y computadora,
                permitiendo una enseñanza del Ingles más atrayente, interactiva
                y efectiva. FluentWorlds brinda nuevas soluciones digitales para
                el aprendizaje de idiomas.
              </p>
              <div className={styles.rightBottom}>
                <Link to="/we">+ Sobre nosotros</Link>
                <Link to="/frequentQuestions">+ Preguntas Frecuentes</Link>
              </div>
            </div>
          </div>

          <div className={styles.sectionFive}>
            <h1>Nuestros planes</h1>
            <h4>
              Elige tu opción ideal de <span>suscripción a continuación</span>
            </h4>
            <Subscriptions />
          </div>

          <div className={styles.sectionSix}>
            <iframe
              title="FluentWorlds una nueva tecnologia para aprender Ingles jugando"
              width="50%"
              height="auto"
              src={youtubeVideo_1}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <iframe
              title="David and Larry King Interview Colombia"
              width="50%"
              height="auto"
              src={youtubeVideo_2}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <div className={styles.sectionSeven}>
            {/* <WorldBackground className={styles.sectionSevenBackground} /> */}
            <div className={styles.left}>
              <h1>Aprender</h1>
              <h1>inglés puede abrirte muchas puertas.</h1>
              <h3>
                Un sistema diferente <span>para aprender inglés en 3D.</span>
              </h3>
            </div>
            <div className={styles.right}>
              <div className={styles.itemContainer}>
                <div className={styles.item}>
                  <div className={styles.colOne}>
                    {/* <CommerceIcon className={styles.sectionSevenIcon} /> */}
                    <img
                      src={CommerceIcon}
                      alt="commerce"
                      className={styles.sectionSevenIcon}
                    />
                  </div>
                  <div className={styles.colTwo}>
                    <h3>01.</h3>
                    <p>
                      Las personas que hablan inglés son más propensas a tener
                      un aumento de ingresos de hasta un 300%.
                    </p>
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.colOne}>
                    {/* <EyeIcon className={styles.sectionSevenIcon} /> */}
                    <img
                      src={EyeIcon}
                      alt="eye"
                      className={styles.sectionSevenIcon}
                    />
                  </div>
                  <div className={styles.colTwo}>
                    <h3>02.</h3>
                    <p>
                      El sistema de lenguaje virtual inmersivo en 3D, ayuda a
                      las personas a dominar un lenguaje en contexto.
                    </p>
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.colOne}>
                    {/* <GlobeIcon className={styles.sectionSevenIcon} /> */}
                    <img
                      src={GlobeIcon}
                      alt="globe"
                      className={styles.sectionSevenIcon}
                    />
                  </div>
                  <div className={styles.colTwo}>
                    <h3>03.</h3>
                    <p>
                      El Inglés es el idioma del internet, de los negocios
                      internacionales y programación.
                    </p>
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.colOne}>
                    {/* <WorldOneIcon className={styles.sectionSevenIcon} /> */}
                    <img
                      src={WorldOneIcon}
                      alt="world"
                      className={styles.sectionSevenIcon}
                    />
                  </div>
                  <div className={styles.colTwo}>
                    <h3>04.</h3>
                    <p>
                      Las personas que hablan Inglés experimentan mejores
                      oportunidades laborales globales.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!isAuthenticated && <RegistrationRibbon />}
        </main>
      </Layout>
    </div>
  )
}

export default Index
