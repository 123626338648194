import styled from '@emotion/styled'

const RibbonContainer = styled.div`
  background-color: #0080fa;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
  @media screen and (max-width: 575px) {
    padding-top: 2%;
    padding-bottom: 2%;
    flex-direction: column;
    justify-content: space-around;
  }
`

export default RibbonContainer
